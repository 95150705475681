import request from '@/utils/request';

export async function query(params:any): Promise<any> {
  return request('users',{
    method:'GET',
    'params':params
  });
}
export async function queryUserRecord(uid:number):Promise<any>{
  const uri = `users/${uid}/change_records`;
  return request(uri,{
    method:"GET"
  })
}

export async function queryCurrent(): Promise<any> {
  return request('administrators');
}

export async function changeUser({uid,...userInfo}:any): Promise<any>{
  console.log(userInfo);
  const uri = `users/${uid}`;
  return request(uri,{
    method:"PUT",
    data:userInfo,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    requestType: 'form',
  })
}

export async function queryNotices(): Promise<any> {
  return request('notices');
}
export async function queryMenuData():Promise<any>{
  return request('menus');
}
