import { stringify } from 'querystring';
import { history, Reducer, Effect } from 'umi';

import { accountLogin,ddUrl } from '@/services/login';
import { sendOtp } from '@/services/global';
import { setAuthority } from '@/utils/authority';
import { getPageQuery } from '@/utils/utils';

import {decode} from 'base-64'

export interface StateType {
  status?: 'ok' | 'error';
  currentAuthority?: 'user' | 'guest' | 'admin';
  ddUrl?:string;
  otpCodeToken?:string;
}

export interface LoginModelType {
  namespace: string;
  state: StateType;
  effects: {
    login: Effect;
    logout: Effect;
    getDDurl:Effect;
    sendOtp:Effect;
  };
  reducers: {
    changeLoginStatus: Reducer<StateType>;
    changeDdUrl:Reducer<StateType>;
    changeOtpCode:Reducer<StateType>;
  };
}

const Model: LoginModelType = {
  namespace: 'login',

  state: {
    status: undefined,
  },

  effects: {
    *sendOtp({payload,callback},{call,put}){
      const response = yield call(sendOtp,payload);
      yield put({
        type:'changeOtpCode',
        payload:response
      });
      callback(response)
    },
    *getDDurl({payload},{call,put}){
      const response =  yield call(ddUrl,payload);
      yield put({
        type:'changeDdUrl',
        payload:response.url
      })
    },
    *login({ payload }, { call, put }) {
      const response = yield call(accountLogin, payload);
      yield put({
        type: 'changeLoginStatus',
        payload: {authorization:response.authorization},
      });
      // Login successfully
      if (response) {
        const urlParams = new URL(window.location.href);
        const params = getPageQuery();
        let { redirect } = params as { redirect: string };
        if (redirect) {
          const redirectUrlParams = new URL(redirect);
          if (redirectUrlParams.origin === urlParams.origin) {
            redirect = redirect.substr(urlParams.origin.length);
            if (redirect.match(/^\/.*#/)) {
              redirect = redirect.substr(redirect.indexOf('#') + 1);
            }
          } else {
            window.location.href = '/';
            return;
          }
        }
        history.replace(redirect || '/');
      }
    },

    logout() {
      const { redirect } = getPageQuery();
      // Note: There may be security issues, please note
      if (window.location.pathname !== '/login' && !redirect) {
        localStorage.removeItem('authorization');
        history.replace({
          pathname: '/login',
          search: stringify({
            redirect: window.location.href,
          }),
        });
      }
    },
  },

  reducers: {
    changeDdUrl(state,{payload}){
      return {
        ...state,
        ddUrl:payload
      }
    },
    changeOtpCode(state,{payload}){
      return {
        ...state,
        otpCodeToken:payload.uuid
      }
    },
    changeLoginStatus(state, { payload }) {
      const aAuthorization = payload.authorization?.split('.',3);
      if(aAuthorization&&aAuthorization.length===3&&aAuthorization[1]){
        const aPayload = JSON.parse(decode(aAuthorization[1]));
        if(typeof(aPayload) ===  "object"){
          setAuthority('guest');// 默认游客菜单权限
          localStorage.setItem('authorization',payload.authorization);
          return {
            ...state,
            status: 'ok',
          };
        }
      }
      return {
        ...state,
        status: 'error',
      };
    },
  },
};

export default Model;
