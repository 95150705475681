import request from '@/utils/request';

export interface Merchant{
   uid?:number,
   spid?:string,
   display_name?:string,
   trading_name?:string,
   registered_name?:string,
   email?:string,
   business_address?:string,
   contact_number?:string,
   nature?:string,
   industry_type?:string,
   structure?:string,
   owner_name?:string,
   owner_position?:string,
   owner_mobile?:string,
   owner_email?:string,
   owner_present_address?:string,
   owner_birthday?:string,
   owner_birth_place?:string,
   owner_nationality?:string,
   owner_nature?:string,
   owner_source_funds?:string,
   agent_name?:string,
   agent_position?:string,
   agent_mobile?:string,
   agent_email?:string,
   avatar?:string,
   settlement_rate?:number,
   trading_limit?:number,
   auto_cash_out?:number,
   retaining_balance?:number,
   is_hide_balance?:number,
   level?:number,
   apply_time?:number,
   verify_time?:number,
   risk_level?:number,
   risk_memo?:string,
   risk_time?:number,
   operator_id?:number,
   operator_name?:string,
   state?:number,
   document_type?:number,
   officer?:{
    name:string,
    mobile:string,
    current_address:string,
    birthday:string,
    nationality:string,
    nature:string,
    source_funds:string
   },
   bank?:{
    id:string,
    bank_name:string,
    account_number:string,
    account_name:string
   },
   certs?:{
    structure1?:string,
    structure2?:string,
    structure3?:string,
    structure4?:string,
    structure5?:string,
    structure6?:string,
    structure7?:string,
    structure8?:string,
    structure9?:string,
    structure10?:string,
    structure11?:string,
    structure12?:string,
    structure13?:string,
    structure14?:string,
    in_store?:string,
    counter?:string,
    storefront?:string,
    government_id?:string,
    owner_creid:string
   }
}

export async function fetch(req:any): Promise<any> {
    const uri = `merchants/${req.spid}`;
  return request(uri,{
      method:"GET",
  });
}

export async function createMerchant(params:Merchant):Promise<any>{
  return request('merchants',{
    method:"POST",
    data:params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    requestType: 'form',
  })
}

export async function editMerchant(params:Merchant):Promise<any>{
  const uri = `merchants/${params.uid}`;
  return request(uri,{
    method:"PATCH",
    data:params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    requestType: 'form',
  })
}