export default {
  'registration_time': 'Registration Time',
  'registration_name':"Registration Name",
  "display_name":"Display Name",
  'account':'Account',
  'account_level':"Account Level",
  "birthday":"Birthday",
  "account_status":"Account Status",
  'settlement_rate':"Settlement Rate",
  'status':"Status",
  'action':"Actions",
  'real_name':"Real Name",
  'partner_id':"Partner Id",
  'detail.title':'Account Detail',
  'detail.avatar':'Avatar',
  'detail.registration_date':"Registration Date",
  'detail.uid':"User Id",
  'detail.mobile':"Mobile No.",
  'detail.name':"Name",
  'detail.nick_name':"Nick Name",
  'detail.birthday':"Date Of Birth",
  'detail.gender':"Gender",
  'detail.level':"Account Level",
  'detail.state':"Account Status",
  'detail.email':"Email",
  'detail.nationality':"Nationality",
  'detail.birth_place':"Place Of Birth",
  'detail.current_address':"Current Address",
  'detail.permanent_address':"Permanent Address",
  'detail.id_type':"ID Type",
  'detail.id_number':"ID Number",
  'detail.employment_status':"Employment Status",
  'detail.business_name':"Business Name",
  'detail.source_funds':"Sources of funds",
  'detail.unfreeze':'Unfreeze the account',
  'detail.freeze':'Freeze the account',
  'detail.terminate':'Terminate the account',
  'detail.history':"Account Status Change History",
  'detail.history.op_date':"Operator Date",
  'detail.history.content':"Operator Content",
  'detail.history.operator':"Operator",
  'detail.phone_number':"Phone Number",
  'Total balance of individuals':"Total balance of individuals",
};
