import { Reducer, Effect } from 'umi';
import { queryApplication,queryChangeList } from '@/services/application';

export interface ModalState {
    appInfo:any;
    changeList:Array<any>
}

export interface ModelType {
  namespace: string;
  state: ModalState;
  effects: {
    queryChangeList: Effect;
    queryApplication: Effect;
  };
  reducers: {
    saveChangeList: Reducer<ModalState>;
    saveApplication: Reducer<ModalState>;
  };
}

const Model: ModelType = {
  namespace: 'application',

  state: {
    appInfo: {},
    changeList:[]
  },

  effects: {
        *queryChangeList({payload},{call,put}){
            const response = yield call(queryChangeList,payload);
            yield put({
                type:'saveChangeList',
                payload:response
            })
        },
        *queryApplication({payload}, { call, put }) {
        const response = yield call(queryApplication,payload);
        yield put({
            type: 'saveApplication',
            payload: response,
        });
        }
  },

  reducers: {
    saveChangeList(state,action){
        return {
            ...(state as ModalState),
            changeList:action.payload ||[]
        }
    },
    saveApplication(state, action) {
      if(action.payload){
        return {
          ...(state as ModalState),
          appInfo: action.payload || {},
        };
      }
      return state as ModalState;
    },
  },
};

export default Model;
