import { qryVersions } from '@/pages/setting/service';
import { Effect, Reducer } from 'umi';

interface VersionData{
    id?:number,
    code:number,
    device:1,
    name:string,
    is_force:0|1,
    status:0|1,
    file_url:string,
    explain?:string,
    memo?:string,
    down_at?:string,
    created_at?:string
}
export interface appVersionModelState{
    currentVersion?:VersionData,
    historyVersions:{
        total:number,
        current:number,
        pageSize:number,
        maxCode:number,
        data:Array<VersionData>
    },
}

export interface VersionModelType {
    namespace: 'appVersion';
    state: appVersionModelState;
    effects: {
      fetch: Effect;
    };
    reducers: {
      saveState:Reducer<appVersionModelState>;
    };
}

const VersionModel:VersionModelType={
    namespace: "appVersion",
    state:{
        currentVersion:undefined,
        historyVersions:{
            total:0,
            current:1,
            pageSize:10,
            maxCode:0,
            data:[]
        }
    },
    effects:{
        *fetch({payload},{call,put}){
           const response = yield call(qryVersions,{status:0});
           let currentVersion;
           let historyVersions;
           if(typeof response.data!=='undefined'){
               [currentVersion] = response.data;
           }else{
               currentVersion = {};
           }
           const versions = yield call(qryVersions,{...payload,status:1});
           if(typeof versions.data!=='undefined'){
                historyVersions = {
                    total:versions.total,
                    current:versions.current,
                    pageSize:versions.pageSize,
                    maxCode:versions.maxCode,
                    data:versions.data
                }
           }
           yield put({
            type:'saveState',
            payload:{currentVersion,historyVersions}
          });
        }
    },
    reducers:{
        saveState(state,action){
            return {
                currentVersion:action.payload.currentVersion,
                historyVersions:action.payload.historyVersions
            };
        }
    }
}

export default VersionModel;