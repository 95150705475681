export default {
  'component.tagSelect.expand': 'Expand',
  'component.tagSelect.collapse': 'Collapse',
  'component.tagSelect.all': 'All',
  'component.proTable.search':'Search',
  'component.proTable.reset':'Reset',
  'component.proTable.collapse': 'Collapse',
  'component.proTable.expand': 'Expand',
  'page.range':'No.',
  'page.total':"items"
};
