import request from '@/utils/request';

import {VersionData} from './version';

export  async function queryAdmins(params:any) {
    return request('administrators/all',{
      params
    });
  } 
export async function queryMenu(params:any){
  return request('menus',{
    params
  })
}

export  async function queryLogs(params:any) {
  return request('Logs/all',{
    params
  });
} 

export async function deleteMenu(id:any){
  const uri = `menus/${id}`;
  return request(uri,{
    method:"DELETE",
  })
}

export async function addAdmin(params:any){
  return request('administrators',{
    method:"POST",
    data:{
      ...params
    },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    requestType: 'form',
  })
}
export async function editAdmin({id,...params}: any){
  const uri = `administrators/${id}`;
  return request(uri,{
    method:"PATCH",
    data:{
      ...params
    },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    requestType: 'form',
  })
}

export async function addMenu(params:any){
  return request('menus',{
    method:"POST",
    data:{
      ...params
    },
    headers:{'Content-Type':"application/x-www-form-urlencoded"},
    requestType:"form"
  })
}

export async function queryActions(id:any){
  const uri = `menus/${id}/actions`
  return request(uri)
}

export async function addActions({id,...data}:any){
  const uri = `menus/${id}/actions`;
  return request(uri,{
    method:"POST",
    data,
    headers:{'Content-Type':"application/json"},
    requestType:"json"
  })
}

export async function deleteAction(menuId:any,id:any){
  const uri = `menus/${menuId}/actions/${id}`;
  return request(uri,{
    method:"DELETE",
  })
}

export async function queryRoles(params:any){
  return request("roles",{
    method:"GET",
    params,
  })
}

export async function deleteRole(id:any){
  const uri = `roles/${id}`;
  return request(uri,{
    method:"DELETE"
  })
}

export async function addRole(data:any){
  return request('roles',{
    method:"POST",
    data,
    headers:{'Content-Type':"application/x-www-form-urlencoded"},
    requestType:"form"
  })
}

export async function editRole({id,...data}:any){
  const uri = `roles/${id}`;
  return request(uri,{
    method:"PATCH",
    data,
    headers:{'Content-Type':"application/x-www-form-urlencoded"},
    requestType:"form"
  })
}
export async function editAdminRole({admin_uid,...data}:any){
  const uri = `administrators/${admin_uid}/roles`;
  return request(uri,{
    method:"PATCH",
    data,
    headers:{
      'Content-Type':"application/json"
    },
    requestType:"json"
  })
}

export async function changePermission({role_id,...data}:any){
  const uri = `roles/${role_id}/menus`;
  return request(uri,{
    method:"POST",
    data,
    headers:{'Content-Type':"application/json"},
    requestType:"json"
  })
}

export async function queryRoleAdmin({id}:any){
  const uri = `roles/${id}/administrators`;
  return request(uri,{
    method:"GET",
    headers:{
      'Content-Type':"application/json"
    },
    requestType:"json"
  })
}

export async function editRoleAdmin({role_id,...data}:any){
  const uri = `roles/${role_id}/administrators`;
  return request(uri,{
    method:"PATCH",
    data,
    headers:{
      'Content-Type':"application/json"
    },
    requestType:"json"
  })
}

export async function insertVersion(data:VersionData){
  return request('versions',{
    method:"POST",
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    requestType: 'form',
  })
}

export async function updateVersion({id,...data}:VersionData){
  const uri = `versions/${id}`;
  return request(uri,{
    method:"PATCH",
    data,
    headers:{'Content-Type':'application/x-www-form-urlencoded'},
    requestType:'form'
  })
}

export async function qryVersion({id,code,device}:VersionData,type:'id'|'code'){
  const uri = `versions/${(type==='id'&&id)||code}`;
  return request(uri,{
    method:"GET",
    params:{
      device
    }
  })
}

export async function qryVersions(params:{
  current:number,
  pageSize:number,
  device:1,
  status:0|1
}){
  return request('versions',{
    method:"GET",
    params
  })
}