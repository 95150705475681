import request from '@/utils/request';

export async function fetch(req:any){
    return request('applications',{
        params:req
    })
}

export async function queryApplication(req:any) {
    const uri = `applications/${req.id}`;
  return request(uri,
    {
        method:"GET"
    });
}

export async function queryChangeList(req:any){
    const uri = `applications/${req.app_id}/change_records`;
    return request(uri);
}
export async function modifyApp({app_id,...info}:any){
    const uri = `applications/${app_id}/change_lists`;
    return request(uri,{
        method:"PUT",
        data:{
            ...info
        },
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        requestType: 'form',
    })
}

export async function deleteBind(req:any){
    const uri = `applications/${req.aid}/binds/${req.bid}`;
    return request(uri,{
        method:"DELETE",
    })
}

export async function queryBinds(req:any){
    const {id,...params} = req;
    const uri = `applications/${id}/binds`
    return request(uri,{
        'params':params
    })
}
