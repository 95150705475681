import request from '@/utils/request';

export async function queryCorrect({id,params}:any) {
    const uri = `channels/${id}/corrects`;
    return request(uri, {
      params,
    });
  }
  
export async function queryChannelList(params:any){
    return request('channels',{
        params
    });
}

export async function queryBanks(params:any){
  return request('banks',{
    params
  })
}

export async function addBank(data:any){
  return request('banks',{
    method:"POST",
    data,
    requestType:"form",
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  })
}
export async function editBank({bank_segment,...data}:any){
  const uri = `banks/${bank_segment}`;
  return request(uri,{
    method:"PATCH",
    data,
    requestType:"form",
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  })
}

export async function queryBankElement(bank_segment:number){
  const uri = `banks/${bank_segment}/elements`;
  return request(uri,{
    method:"GET",
  })
}

export async function queryBalances(){
  return request('balances');
}

export async function queryChannelInfo(id:string){
  const uri = `channels/${id}`;
  return request(uri,{
    method:"GET"
  })
}

export async function editChannel(id:string,data:any){
  const uri = `channels/${id}`;
  return request(uri,{
    method:"PATCH",
    data,
    requestType:"form",
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  })
}

export async function queryChannelMode(id:string,busi_type:number){
  const uri = `channels/${id}/modes`;
  return request(uri,{
    method:"GET",
    params:{
      busi_type
    }
  })
}

export async function queryModesConfig({busi_type,bank_channel}:any){
  return request('modes',{
    params:{
      busi_type,
      bank_channel
    }
  });
}

export async function addChannelMode(id:string,data:any){
  return request(`channels/${id}/modes`,{
    method:"POST",
    data,
    requestType:"form",
    headers:{
      'Content-Type':"application/x-www-form-urlencoded"
    }
  })
}

export async function queryBankElementList({
  bank_channel,busi_type,pay_scene,mode_id,reverse,is_update_list,mode_type,real_time
}:{
  bank_channel:string,
  busi_type?:number,
  pay_scene?:number,
  mode_id?:number,
  reverse?:1,
  is_update_list?:1,
  mode_type?:string,
  real_time?:number,
}){
  const uri = `channels/${bank_channel}/banks`;
  return request(uri,{
    method:"GET",
    params:{
      busi_type,
      pay_scene,
      mode_id,
      reverse,
      is_update_list,
      mode_type,real_time
    },
  })
}

export async function modifyChannelMode(bank_channel:string,id:number,{
  service_fee_rate,settle_fee_rate,settle_fee_type,state,settle_fee_fixed,service_fee_type
}:any){
  const uri = `channels/${bank_channel}/modes/${id}`;
  return request(uri,{
    method:"PATCH",
    data:{
      service_fee_rate,
      settle_fee_rate,
      settle_fee_type,
      state,
      settle_fee_fixed,
      service_fee_type
    },
    requestType:"form",
    headers:{
      'Content-Type':"application/x-www-form-urlencoded"
    }
  })
}

export async function deleteModeBanks(bank_channel:string,id:number,bank_segments:Array<number>){
  const uri = `channels/${bank_channel}/modes/${id}/banks`;
  return request(uri,{
    method:"DELETE",
    data:{    bank_segments:[...bank_segments],},
    requestType:"json"
  })
}

export async function updateModeBanks(bank_channel:string,id:number,bank_segments:Array<number>,update_flag:number){
  const uri =`channels/${bank_channel}/modes/${id}/banks`;
  return request(uri,{
    method:"PATCH",
    params:{
      update_flag
    },
    data:{
      bank_segments:[...bank_segments],
    },
    requestType:"json"
  })
}

export async function pRateConfig(){
  return request('prates',{
    method:"GET"
  })
}

export async function updatePRate(platformFeeConfig:{
  platformFeeSwitch:boolean,
  platformFeeRealTimeCashin:{
    feeType:number,
    feeValue:number
  },
  platformFeeRealTimeCashout:{
    feeType:number,
    feeValue:number
  },
  platformFeeNonTimeCashin:{
    feeType:number,
    feeValue:number
  },
  platformFeeNonTimeCashout:{
    feeType:number,
    feeValue:number
  }
}){
  return request('prates',{
    method:"PUT",
    data:platformFeeConfig,
    requestType:"json"
  })
}

export async function getAmlcs(params:any){
  const {report_at,amount,...reset} = params;
  if(report_at){
    [reset.report_at_start,reset.report_at_end] = report_at;
  }
  if(amount){
    [reset.amount_min,reset.amount_max] = amount
  }
  return request('amlcs',{
    method:"GET",
    params:reset
  })
}

export async function getFwsSettlement(params:any){
  const {report_at,amount,...reset} = params;
  // if(report_at){
  //   [reset.report_at_start,reset.report_at_end] = report_at;
  // }
  // if(amount){
  //   [reset.amount_min,reset.amount_max] = amount
  // }
  return request('mch_settles',{
    method:"GET",
    params:reset
  })
}

export function getFwsSettlementInfo(id:number){
  const uri = `mch_settles/${id}`;
  return request(uri,{
    method:"GET",
    responseType:"blob",
    getResponse:true
  })
}

export function updateSettleStatus(id:number){
  const uri = `mch_settles/${id}`;
  return request(uri,{
    method:"POST",
    responseType:"blob",
    getResponse:true
  })
}

export function getAmlc(id:number){
  const uri = `amlcs/${id}`;
  return request(uri,{
    method:"GET",
    responseType:"blob",
    getResponse:true
  })
}