import { Effect, Reducer } from 'umi';

import { queryCurrent, query as queryUsers,queryUserRecord,changeUser} from '@/services/user';
import { setAuthority } from '@/utils/authority';
import { MenuDataItem } from '@ant-design/pro-layout';

export interface CurrentUser {
  admin_uid?:string;
  name?:string;
  state?:string;
  mobile?:string;
  email?:string;
  avatar?: string;
  authority?:string|Array<string>;
  menus?:MenuDataItem
}

export const UserState = {
  1:"Created",
  2:"Normal",
  3:"Frozen",
  10:"Terminated"
}

export interface UserModelState {
  currentUser?: CurrentUser;
  totalBalance?:number;
  operatorRecord?:{
    content:string,
    operator_id:number,
    operator_name:string
  }[]
}

export interface UserModelType {
  namespace: 'user';
  state: UserModelState;
  effects: {
    fetch: Effect;
    fetchCurrent: Effect;
    fetchCurrentIndivdual:Effect;
    changeUserState:Effect;
  };
  reducers: {
    saveCurrentIndivdual:Reducer<UserModelState>;
    saveCurrentUser: Reducer<UserModelState>;
    changeNotifyCount: Reducer<UserModelState>;
  };
}

const UserModel: UserModelType = {
  namespace: 'user',

  state: {
    currentUser: {},
  },

  effects: {
    *changeUserState({payload,callback},{call}){
      yield call(changeUser,{
        // uid:payload.uid,
        // state:payload.state
        // note:payload.note
        ...payload
      });
      callback();
    },
    *fetchCurrentIndivdual({payload},{call,put}){
      const response = yield call(queryUserRecord,payload);
      yield put({
        type:'saveCurrentIndivdual',
        payload:response
      })
    },
    *fetch(_, { call, put }) {
      const response = yield call(queryUsers);
      yield put({
        type: 'save',
        payload: response,
      });
    },
    *fetchCurrent(_, { call, put }) {
      const response = yield call(queryCurrent);
      yield put({
        type: 'saveCurrentUser',
        payload: response,
      });
    },
  },

  reducers: {
    saveCurrentIndivdual(state,action){
      return {
        ...state,
        operatorRecord:action.payload
      }
    },
    saveCurrentUser(state, action) {
      setAuthority(action.payload.authority);
      return {
        ...state,
        currentUser: action.payload || {},
      };
    },
    changeNotifyCount(
      state = {
        currentUser: {},
      },
      action,
    ) {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          notifyCount: action.payload.totalCount,
          unreadCount: action.payload.unreadCount,
        },
      };
    },
  },
};

export default UserModel;
