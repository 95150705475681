export default {
  'menu.login': 'Login',
  'menu.home': 'Home',
  'menu.account': 'Account Info',
  'menu.account.individual': 'Individual',
  'menu.account.partner': 'Partner',
  'menu.transaction': 'Transaction Record',
  'menu.transaction.all': 'All',
  'menu.transaction.cash-in': 'Cash In',
  'menu.transaction.cash-out': 'Cash out',
  'menu.transaction.transfer': 'Transfer',
  'menu.transaction.payment': 'Payment',
  'menu.transaction.refund': 'Refund',
  'menu.application': 'Applications',
  'menu.application.review': 'Application Review',
  'menu.application.list': 'Application List',
  'menu.application.detail': 'Application Detail',
  'menu.audit': 'Audit',
  'menu.audit.kyc': 'Individual Level 1',
  'menu.audit.facetoface': 'Individual Level 2',
  'menu.audit.three': 'Individual Level 3',
  'menu.audit.merchant': 'Merchant Review',
  'menu.audit.transfer': 'Merchant Batch Transfer Review',
  'menu.audit.charge': 'Merchant Charge Review',
  'menu.finance': 'Finance',
  'menu.finance.channel': 'Funding channels',
  'menu.finance.emi': 'EMI Balance',
  'menu.finance.bank': 'Bank',
  'menu.finance.config': 'Config',
  'menu.finance.settlement': 'Settlement',
  'menu.finance.reconciliation': 'Reconciliation',
  'menu.finance.dtr':'PHUB DTR',
  'menu.finance.fws_settlement':'Settlement',
  'menu.risk': 'Risk Contorl',
  'menu.risk.prohibited': 'Prohibited User',
  'menu.risk.ban-country': 'Ban Country',
  'menu.risk.ctr': 'Covered Transaction Warning',
  'menu.setting': 'Settings',
  'menu.setting.admin': 'Administrator',
  'menu.setting.role': 'Role',
  'menu.setting.menu': 'Menu',
  'menu.setting.notification': 'Notification',
  'menu.otp-login': 'OTP Login',
  'menu.finance.amlc': 'AMLC',
  'menu.setting.version': 'App Version Manage',
  'menu.home_menu': 'App Home Manage',
  'menu.market':"Marketing",
  'menu.market.campaign':"Referral Campaign",
  'menu.market.setting':"Marketing Setting"
};
