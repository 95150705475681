export default {
  'registration_time': '注册时间',
  'registration_name':"注册名",
  "display_name":"显示名",
  'account':"账号",
  'account_level':"账号等级",
  "birthday":"生日",
  'account_status':"账号状态",
  'settlement_rate':"结算费率",
  'status':"状态",
  'action':"操作",
  'real_name':"真实姓名",
  'partner_id':"合作商ID",
  'detail.title':'账号详情',
  'detail.avatar':'头像',
  'detail.registration_date':"注册时间",
  'detail.uid':"用户ID",
  'detail.mobile':"手机号",
  'detail.name':"姓名",
  'detail.nick_name':"昵称",
  'detail.birthday':"生日",
  'detail.gender':"性别",
  'detail.level':"账号等级",
  'detail.state':"账号状态",
  'detail.email':"邮件",
  'detail.nationality':"国籍",
  'detail.birth_place':"出生地",
  'detail.current_address':"当前所在地址",
  'detail.permanent_address':"固定地址",
  'detail.id_type':"证件类型",
  'detail.id_number':"证件号",
  'detail.employment_status':"就业状态",
  'detail.business_name':"店铺名",
  'detail.source_funds':"收入来源",
  'detail.unfreeze':'解冻账户',
  'detail.freeze':'冻结账户',
  'detail.terminate':'终止用户',
  'detail.history':"账户状态变更历史",
  'detail.history.op_date':"操作时间",
  'detail.history.content':"操作内容",
  'detail.history.operator':"操作员",
  'detail.phone_number':"手机号",
  'Total balance of individuals':'个人余额总数',
};
