export default {
  'component.tagSelect.expand': '展开',
  'component.tagSelect.collapse': '收起',
  'component.tagSelect.all': '全部',
  'component.proTable.search':'搜索',
  'component.proTable.reset':'重置',
  'component.proTable.collapse': '收起',
  'component.proTable.expand': '展开',
  'page.range':'第',
  'page.total':"总共"
};
