import { Effect } from 'umi';

import { fetch,createMerchant,editMerchant } from '@/services/merchant';

export interface StateType {
    detail:any
}

export interface MerchantModelType {
  namespace: string;
  state: StateType;
  effects: {
    fetch: Effect;
    create:Effect;
    edit:Effect;
  };
  reducers: {
  };
}

const Model: MerchantModelType = {
  namespace: 'merchant',

  state: {
    detail:{} as any,
  },

  effects: {
    *create({payload,callback},{call}){
       const res = yield call(createMerchant,payload);
       callback(res);
    },
    *fetch({payload,callback},{call}){
        const response = yield call(fetch,payload);
        callback(response)
    },
    *edit({payload,callback},{call}){
      const response = yield call(editMerchant,payload);
      callback(response);
    }
  },

  reducers: {
  },
};

export default Model;
