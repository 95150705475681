import request from '@/utils/request';
import { set } from 'lodash';

export interface LoginParamsType {
  mode:1|2|3,// 1qrcode 登录 2 验证码登录 3账号密码登录
  loginName?: string, // 钉钉验证码登录时用login_name
  uuid?:string, // 验证码uuid
  code:string, // 钉钉验证码 或 钉钉Oauth2回调code
  password?:string,// 密码
}

export async function fakeAccountLogin(params: LoginParamsType) {
  return request('login/account', {
    method: 'POST',
    data: params,
  });
}

export async function accountLogin(params:LoginParamsType){
  return request('authorizations',{
    method:"POST",
    data:params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    requestType: 'form',
  })
}

export async function getFakeCaptcha(mobile: string) {
  return request(`login/captcha?mobile=${mobile}`);
}

export async function ddUrl(params:any){
  return request(`ddurls`,{
    params:{
      ...params
    }
  });
}
