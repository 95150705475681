// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from 'D:/phpstudy_pro/WWW/bnnp-crm.svn/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/components/PageLoading/index';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BlankLayout' */'D:/phpstudy_pro/WWW/bnnp-crm.svn/src/layouts/BlankLayout'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/login",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__UserLayout' */'D:/phpstudy_pro/WWW/bnnp-crm.svn/src/layouts/UserLayout'), loading: LoadingComponent}),
        "routes": [
          {
            "name": "login",
            "path": "/login",
            "icon": "smile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__login' */'D:/phpstudy_pro/WWW/bnnp-crm.svn/src/pages/login'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "otp-login",
            "icon": "smile",
            "path": "/login/otp",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__login__otp' */'D:/phpstudy_pro/WWW/bnnp-crm.svn/src/pages/login/otp'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "pwd-login",
            "path": "/login/pwd",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__login__pwd' */'D:/phpstudy_pro/WWW/bnnp-crm.svn/src/pages/login/pwd'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'D:/phpstudy_pro/WWW/bnnp-crm.svn/src/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__SecurityLayout' */'D:/phpstudy_pro/WWW/bnnp-crm.svn/src/layouts/SecurityLayout'), loading: LoadingComponent}),
        "routes": [
          {
            "path": "/",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BasicLayout' */'D:/phpstudy_pro/WWW/bnnp-crm.svn/src/layouts/BasicLayout'), loading: LoadingComponent}),
            "Routes": [
              "src/pages/Authorized"
            ],
            "authority": [
              "admin",
              "user"
            ],
            "routes": [
              {
                "path": "/account",
                "name": "account",
                "routes": [
                  {
                    "name": "individual",
                    "path": "/account/individual",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__account__individual' */'D:/phpstudy_pro/WWW/bnnp-crm.svn/src/pages/account/individual'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "partner",
                    "path": "/account/partner",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__account__partner' */'D:/phpstudy_pro/WWW/bnnp-crm.svn/src/pages/account/partner'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/transaction",
                "name": "transaction",
                "routes": [
                  {
                    "name": "cash-in",
                    "path": "/transaction/cash-in",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__transaction__cash-in' */'D:/phpstudy_pro/WWW/bnnp-crm.svn/src/pages/transaction/cash-in'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "cash-out",
                    "path": "/transaction/cash-out",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__transaction__cash-out' */'D:/phpstudy_pro/WWW/bnnp-crm.svn/src/pages/transaction/cash-out'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "transfer",
                    "path": "/transaction/transfer",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__transaction__transfer' */'D:/phpstudy_pro/WWW/bnnp-crm.svn/src/pages/transaction/transfer'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "payment",
                    "path": "/transaction/payment",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__transaction__payment' */'D:/phpstudy_pro/WWW/bnnp-crm.svn/src/pages/transaction/payment'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "refund",
                    "path": "/transaction/refund",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__transaction__refund' */'D:/phpstudy_pro/WWW/bnnp-crm.svn/src/pages/transaction/refund'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/application",
                "name": "application",
                "routes": [
                  {
                    "path": "/application/review",
                    "name": "review",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__application__review' */'D:/phpstudy_pro/WWW/bnnp-crm.svn/src/pages/application/review'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/application/list",
                    "name": "list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__application__list__index' */'D:/phpstudy_pro/WWW/bnnp-crm.svn/src/pages/application/list/index'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/application/detail",
                    "name": "detail",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__application__list__detail' */'D:/phpstudy_pro/WWW/bnnp-crm.svn/src/pages/application/list/detail'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/audit",
                "name": "audit",
                "routes": [
                  {
                    "path": "/audit/kyc",
                    "name": "kyc",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__audit__kyc' */'D:/phpstudy_pro/WWW/bnnp-crm.svn/src/pages/audit/kyc'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/audit/signature",
                    "name": "Signature",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__audit__signature' */'D:/phpstudy_pro/WWW/bnnp-crm.svn/src/pages/audit/signature'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/audit/facetoface",
                    "name": "facetoface",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__audit__facetoface' */'D:/phpstudy_pro/WWW/bnnp-crm.svn/src/pages/audit/facetoface'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/audit/three",
                    "name": "three",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__audit__three' */'D:/phpstudy_pro/WWW/bnnp-crm.svn/src/pages/audit/three'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/audit/merchant",
                    "name": "merchant",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__audit__merchant' */'D:/phpstudy_pro/WWW/bnnp-crm.svn/src/pages/audit/merchant'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/audit/transfer",
                    "name": "transfer",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__audit__transfer' */'D:/phpstudy_pro/WWW/bnnp-crm.svn/src/pages/audit/transfer'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/audit/charge",
                    "name": "charge",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__audit__charge' */'D:/phpstudy_pro/WWW/bnnp-crm.svn/src/pages/audit/charge'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/finance",
                "name": "finance",
                "routes": [
                  {
                    "path": "/finance/channel",
                    "name": "channel",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__finance__channel' */'D:/phpstudy_pro/WWW/bnnp-crm.svn/src/pages/finance/channel'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/finance/bank",
                    "name": "bank",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__finance__bank' */'D:/phpstudy_pro/WWW/bnnp-crm.svn/src/pages/finance/bank'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/finance/amlc",
                    "name": "amlc",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__finance__amlc' */'D:/phpstudy_pro/WWW/bnnp-crm.svn/src/pages/finance/amlc'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/finance/emi",
                    "name": "emi",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__finance__emi' */'D:/phpstudy_pro/WWW/bnnp-crm.svn/src/pages/finance/emi'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/finance/channel/reconciliation",
                    "name": "reconciliation",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__finance__reconciliation' */'D:/phpstudy_pro/WWW/bnnp-crm.svn/src/pages/finance/reconciliation'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/finance/channel/settlement",
                    "name": "settlement",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__finance__settlement' */'D:/phpstudy_pro/WWW/bnnp-crm.svn/src/pages/finance/settlement'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/finance/channel/config",
                    "name": "config",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__finance__config' */'D:/phpstudy_pro/WWW/bnnp-crm.svn/src/pages/finance/config'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/finance/dtr",
                    "name": "dtr",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__finance__bfscs-dtr' */'D:/phpstudy_pro/WWW/bnnp-crm.svn/src/pages/finance/bfscs-dtr'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/finance/fws_settlement",
                    "name": "fws_settlement",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__finance__fws_settlement' */'D:/phpstudy_pro/WWW/bnnp-crm.svn/src/pages/finance/fws_settlement'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/market",
                "name": "market",
                "routes": [
                  {
                    "path": "/market/campaign",
                    "name": "campaign",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__market__campaign' */'D:/phpstudy_pro/WWW/bnnp-crm.svn/src/pages/market/campaign'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/market/setting",
                    "name": "setting",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__market__setting' */'D:/phpstudy_pro/WWW/bnnp-crm.svn/src/pages/market/setting'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/setting",
                "name": "setting",
                "routes": [
                  {
                    "path": "/setting/admin",
                    "name": "admin",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__setting__admin' */'D:/phpstudy_pro/WWW/bnnp-crm.svn/src/pages/setting/admin'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/setting/log",
                    "name": "log",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__setting__log' */'D:/phpstudy_pro/WWW/bnnp-crm.svn/src/pages/setting/log'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/setting/role",
                    "name": "role",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__setting__role' */'D:/phpstudy_pro/WWW/bnnp-crm.svn/src/pages/setting/role'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/setting/menu",
                    "name": "menu",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__setting__menu' */'D:/phpstudy_pro/WWW/bnnp-crm.svn/src/pages/setting/menu'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/setting/notification",
                    "name": "notification",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__setting__notification' */'D:/phpstudy_pro/WWW/bnnp-crm.svn/src/pages/setting/notification'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/setting/version",
                    "name": "version",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__setting__version' */'D:/phpstudy_pro/WWW/bnnp-crm.svn/src/pages/setting/version'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/menu",
                "name": "home_menu",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__menu' */'D:/phpstudy_pro/WWW/bnnp-crm.svn/src/pages/menu'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Welcome' */'D:/phpstudy_pro/WWW/bnnp-crm.svn/src/pages/Welcome'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          }
        ]
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
