import request from '@/utils/request';


export async function fetchNationals() {
  return request('nationalities', {
    method: 'GET'
  });
}

export async function fetchBanks(){
    return request('banks',{
        method:"GET"
    })
}

export async function sendOtp(params:any){
  return request('otps',{
    method:"POST",
    data:{
      ...params
    },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    requestType: 'form',
  })
}