export default {
  'menu.home': '主页',
  'menu.login': '登录',
  'menu.account': '账户信息',
  'menu.account.individual': '个人用户',
  'menu.account.partner': '商户信息',
  'menu.transaction': '交易记录',
  'menu.transaction.all': '全部记录',
  'menu.transaction.cash-in': '充值记录',
  'menu.transaction.cash-out': '提现记录',
  'menu.transaction.transfer': '转账记录',
  'menu.transaction.payment': '支付记录',
  'menu.transaction.refund': '退款记录',
  'menu.application': '商户应用',
  'menu.application.review': '应用审核',
  'menu.application.list': '应用列表',
  'menu.application.detail': '应用详情',
  'menu.audit': '后台审核',
  'menu.audit.kyc': '实名审核',
  'menu.audit.signature': '签名审核',
  'menu.audit.facetoface': '面对面审核',
  'menu.audit.three': '等级3审核',
  'menu.audit.transfer': '商户批量转账审核',
  'menu.audit.charge': '商户手工充值审核',
  'menu.audit.merchant': '商户审核',
  'menu.finance': '财务管理',
  'menu.finance.channel': '资金渠道',
  'menu.finance.fws_settlement': '资金结算',
  'menu.finance.emi': '余额监控',
  'menu.finance.bank': '银行管理',
  'menu.finance.config': '渠道配置',
  'menu.finance.settlement': '结算',
  'menu.finance.reconciliation': '对账',
  'menu.finance.dtr':'PHUB DTR',
  'menu.risk': '风险控制',
  'menu.risk.prohibited': '阻止用户',
  'menu.risk.ban-country': '禁止国籍',
  'menu.risk.ctr': 'CTR告警',
  'menu.setting': '后台设置',
  'menu.setting.admin': '后台用户管理',
  'menu.setting.log': '操作日志管理',
  'menu.setting.role': '角色管理',
  'menu.setting.menu': '菜单管理',
  'menu.setting.notification': '消息管理',
  'menu.otp-login': '验证码登录',
  'menu.finance.amlc': '告警记录',
  'menu.setting.version': 'APP版本管理',
  'menu.home_menu': '首页功能管理',
  'menu.market':"市场运营活动",
  'menu.market.campaign':"推荐活动",
  'menu.market.setting':"活动设置"
};
